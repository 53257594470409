//
import VTableSurveys from '@/components/tables/surveys'
import PProviderService from '@/services/api/Provider/PProvider'
import GScheduleMeetingService from '@/services/api/General/GScheduleMeeting'
import RulesService from '@/services/rules'

//
import VViewProviderReport from '@/views/Provider/PProvider/report'

//
export default {
  name: 'v-view-home',
  components: {
    VTableSurveys,
    VViewProviderReport,
  },
  computed: {

    /**
     * 
     */
    pProviderId() {
      return atob(this.$route.params.permalink).split('-').pop()
    },

    /**
     * 
     */
    isMe() {
      return this.pProviderId == this.$provider.id
    },
  },
  data() {
    return {
      busy: {
        documents: false,
        projects: false,
        services: false,
        provider: false,
        match: false,
        unblock: false,
        unblockFaena: false,
        zoom: false,
      },
      dialogs: {
        match: false,
        project: false,
        service: false,
        review: false,
        reviewFaena: false,
        unblock: false,
        unblockFaena: false,
        zoom: false,
        survey: false,
        surveyFaena: false,
      },
      message: '',
      selected: {
        areas: null,
        service: null,
      },
      gScheduleMeeting: {},
      pProvider: {
        category: {},
        commune: {},
        areas: [],
        services: [],
        size: {},
        title: '',
      },
      pProviderDocuments: {
        data: [],
      },
      pProviderProjects: {
        data: [],
      },
      pProviderProject: {},
      pProviderService: {},
      pProviderSurvey: {
        questions: [],
      },
      pProviderSurveyFaena: {
        questions: [],
      },
    }    
  },
  methods: {

    formateaRut(rut) {

      var actual = rut.replace(/^0+/, "");
      if (actual != '' && actual.length > 1) {
          var sinPuntos = actual.replace(/\./g, "");
          var actualLimpio = sinPuntos.replace(/-/g, "");
          var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
          var rutPuntos = "";
          var i = 0;
          var j = 1;
          for (i = inicio.length - 1; i >= 0; i--) {
              var letra = inicio.charAt(i);
              rutPuntos = letra + rutPuntos;
              if (j % 3 == 0 && j <= inicio.length - 1) {
                  rutPuntos = "." + rutPuntos;
              }
              j++;
          }
          var dv = actualLimpio.substring(actualLimpio.length - 1);
          rutPuntos = rutPuntos + "-" + dv;
      }
      return rutPuntos;
  },

    //metodo para verificar si es admin o es compañía miner o proveedor para hacer un match
    handleContactOption(){
      if(this.isAdmin || this.isMiningCompany || this.isProvider) return true
      else return false

    },
    /**
     * 
     */
    mapArea(index) {
      return this.pProvider.areas[index]
    },

    /**
     * 
     */
    getArea() {
      return this.pProvider.areas[this.selected.areas]
    },

    /**
     * 
     */
    async getProvider() {
      this.busy.provider = true
      this.pProvider = await PProviderService.getById(this.pProviderId)
      this.busy.provider = false
    },

    /**
     * 
     */
    async getDocumentsProjects() {
      this.busy.documents = true
      this.pProviderDocuments = await PProviderService.getAllDocumentsById(this.pProviderId)
      this.busy.documents = false
    },

    /**
     * 
     */
    async getProviderProjects() {
      this.busy.projects = true
      this.pProviderProjects = await PProviderService.getAllProjectsById(this.pProviderId)
      this.busy.projects = false
    },

    /**
     * 
     */
    async getProviderDocuments() {
      this.busy.documents = true
      this.pProviderDocuments = await PProviderService.getAllDocumentsById(this.pProviderId)
      this.busy.documents = false
    },

    /**
     * 
     */
    handleDownloadClick(document) {
      PProviderService.documentDownload(this.pProvider, document)
    },

    /**
     * 
     */
    handleError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    async handleMatchSubmit() {
      this.busy.match = true
      await PProviderService.match(this.pProviderId).then(this.handleSuccess).catch(this.handleError)
      this.busy.match = false
    },

    /**
     * 
     */
    async handleMatchMessageSubmit() {
      this.busy.match = true
      await PProviderService.matchContact(this.getArea(), this.message).then(this.handleSuccess).catch(this.handleError)
      this.dialogs.match = false
      this.busy.match = false
    },

    /**
     * 
     */
    handleReviewSurveyClick() {
      PProviderService.getSurvey(this.pProvider.id).then(res => {
        if(res[0].faena){
          this.pProviderSurvey = res[1].data
        }else{
          this.pProviderSurvey = res[0].data
        }
      })
      this.dialogs.review = true
    },
    handleReviewSurveyClickFaena() {
      console.log(" EL PPROVIDER>>" , this.pProvider)
      PProviderService.getSurvey(this.pProvider.id).then(res => {
        if(res[0].faena){
          this.pProviderSurveyFaena= res[0].data
        }else{
          this.pProviderSurveyFaena= res[1].data
        }
      })
      this.dialogs.reviewFaena = true
    },

    /**
     * 
     * @param {*} payload 
     */
    handleScoreClick() {
      if (this.isMiningCompany || this.isMiningCompanyAdmin || this.isAdmin || this.isMe) {
        this.dialogs.survey = true  
      }
    },

    /**
     * 
     * @param {*} payload 
     */
    handleScoreFaenaClick() {
      if (this.isMiningCompany || this.isMiningCompanyAdmin || this.isAdmin || this.isMe) {
        this.dialogs.surveyFaena = true
      }
    },

    /**
     * 
     */
    handleSuccess(payload) {
      PProviderService.translate({ response: payload }).then(this.$toast.success)
    },

    /**
     * 
     */
    handleUnblockClick() {
      this.dialogs.unblock = true
    },

    handleUnblockFaenaClick() {
      this.dialogs.unblockFaena = true
    },
    /**
     * 
     */
    async handleUnblockSubmit() {
      this.busy.unblock = true
      await PProviderService.unblockSurvey(this.pProvider.id).then(this.handleUnblockSuccess).catch(this.handleError)
      this.busy.unblock = false
    },

    /**
     * 
     */
    async handleUnblockSubmitFaena() {
      this.busy.unblockFaena = true
      await PProviderService.unblockSurveyFaena(this.pProvider.id).then(this.handleUnblockFaenaSuccess).catch(this.handleError)
      this.busy.unblockFaena = false
    },

    /**
     * 
     */
    handleUnblockSuccess(res) {
      this.$toast.success(res.data.message)
      this.getProvider().then(this.getProviderProjects).then(this.getProviderDocuments)
      this.dialogs.unblock = false
    },

    handleUnblockFaenaSuccess(res) {
      this.$toast.success(res.data.message)
      this.getProvider().then(this.getProviderProjects).then(this.getProviderDocuments)
      this.dialogs.unblockFaena = false
    },

    /**
     * 
     */
    handleWebUrlClick() {
      window.open(this.pProvider.webUrl, '_blank')
    },

    /**
     * 
     */
    handleZoomClick() {
      this.dialogs.zoom = true
    },

    /**
     * 
     */
    handleZoomError(payload) {
      GScheduleMeetingService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleZoomReset() {
      this.dialogs.zoom = false
      this.gScheduleMeeting = GScheduleMeetingService.toCreate()
      this.gScheduleMeeting.providerId = this.pProviderId
    },

    /**
     * 
     */
    async handleZoomSubmit() {
      this.busy.zoom = true
      await GScheduleMeetingService.create(this.gScheduleMeeting).then(this.handleZoomSuccess).catch(this.handleZoomError)
      this.busy.zoom = false
    },

    /**
     * 
     */
    handleZoomSuccess(response) {
      GScheduleMeetingService.translate({ response }).then(this.$toast.success)
      this.dialogs.zoom = false
    },
  },
  created() {
    this.getProvider().then(this.getProviderProjects).then(this.getProviderDocuments)
    this.handleZoomReset()
  },
  props: {

    /**
     * 
     */
    messageRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.notEmpty,
      ],
    },

    /**
     * 
     */
    topicRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.notEmpty,
      ],
    },

    /**
     * 
     */
    descriptionRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.notEmpty,
      ],
    },

    /**
     * 
     */
    startAtRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.notEmpty,
      ],
    },

    /**
     * 
     */
    durationMinutesRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.notEmpty,
      ],
    },
  }
}