<template lang='pug'>
  .v-table-surveys
    template( v-for='(item, index) in items' )
      template( v-if='item.hasSubcategories' )
        h1.grey.lighten-5.px-4.py-2.subtitle-1 
          | {{ index + 1 }}. {{ item.title }}&nbsp;
          v-btn( icon small @click='show = !show' )
            v-icon {{ show ? 'mdi-menu-down' : 'mdi-menu-right' }}
        v-divider
        template( v-if='show' v-for='(subcategory, subindex) in item.subcategories' )
          v-table-survey( :title='index + 1 + "." + (subindex + 1) + " " + subcategory.subcategory' :items='subcategory.questions' )
      template( v-else )
        v-table-survey( :title='index + 1 + ". " + item.title' :items='item.questions' )
</template>

<script>
  //
  import VTableSurvey from '@/components/tables/survey'
  //
  export default {
    components: {
      VTableSurvey,
    },
    data: () => ({
      show: false,
    }),
    props: {

      /**
       * 
       */
      items: {
        type: [Array, Object],
        default: () => [],
      },
    },
  }
</script>