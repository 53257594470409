<template lang='pug'>
  v-simple-table
    template( slot='default' )
      thead
        tr.grey.lighten-5
          th.subtitle-1 
            | {{ title }}&nbsp;
            v-btn( color='accent' icon small @click='show = !show' )
              v-icon {{ show ? 'mdi-menu-down' : 'mdi-menu-right' }}
          th.subtitle-1( style='width: 60px;' ) SI
          th.subtitle-1( style='width: 60px;' ) NO
      tbody( v-if='show' )
        tr( v-for='item in items' )
          td.py-2
            p.ma-0 {{ item[itemQuestion] }}
            p.ma-0.primary--text {{ item[itemComments] == '' ? 'Sin comentarios' : item[itemComments] }}
          td
            v-icon( color='primary' v-if='item[itemAnswer] == true' ) mdi-radiobox-marked
          td
            v-icon( color='primary' v-if='item[itemAnswer] == false' ) mdi-radiobox-marked
</template>

<script>
  export default {
    data: () => ({
      show: false,
    }),
    props: {

      /**
       * 
       */
      itemAnswer: {
        type: String,
        default: 'yesOrNo',
      },

      /**
       * 
       */
      itemComments: {
        type: String,
        default: 'comment',
      },

      /**
       * 
       */
      itemQuestion: {
        type: String,
        default: 'question',
      },

      /**
       * 
       */
      items: {
        type: [Array, Object],
        default: () => [],
      },

      /**
       * 
       */
      title: {
        type: String,
        default: '',      
      },
    },
  }
</script>

<style>
  .theme--light.v-data-table table tbody tr td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }
</style>